import React from 'react';
import { FinnoscoreUploader } from '@charts';
import { Layout, StaticSEO } from '@core';

export default function () {
  return (
    <Layout>
      <StaticSEO pageId="finnoscoreUploader" />
      <FinnoscoreUploader />
    </Layout>
  );
}
